import App from "../../core/facade";

export default (function() {
  App.on("coupons_index:init", function() {
    App.log("Coupons page init");
    App.Coupons();
    App.CouponFilters();

    (function filterByType() {
      const toggleBtn = document.querySelector(".filter-by-type__toggle");
      const dropdown = document.querySelector(".filter-by-type__dropdown");
      const expanded = "filter-by-type__dropdown--expanded";
      const toggleBtnActive = "filter-by-type__toggle--active";

      if (toggleBtn) {
        toggleBtn.addEventListener("click", function(ev) {
          ev.preventDefault();
          if (
            App.Utils.isMobile.tabletPortrait() ||
            App.Utils.isMobile.mobile()
          ) {
            if (dropdown && toggleBtn) {
              dropdown.classList.toggle(expanded);
              toggleBtn.classList.toggle(toggleBtnActive);
            }
          }
        });
      }
    })();
  });
})();

