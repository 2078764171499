import App from "./../core/facade";
import ToggleNewsletter from "./ToggleNewsletter";

export default (function() {

  function run() {
    App.log("Widgets init");
    ToggleNewsletter.run();
  }

  return {
    run: run
  };
})();
