import App from "../../core/facade";

export default (function() {
  App.on("searches_index:init", function() {
    App.log("Search page init");
    App.Coupons();
  });
})();


