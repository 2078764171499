import App from "../../core/facade";

export default (function() {
  App.on("categories_show:init", function() {
    App.log("Categories Show page init");
    App.Categories();
    App.Coupons();
    App.JumpToLinks();
    App.CouponFilters();
  });
})();

