import Facade from "libs/Facade";
import {
  CouponsGrid,
  FeaturedCoupons,
  QuickLinks,
  Carousel,
  PremiumSlider,
  OfferCarousel,
  PremiumCampaignSlider
} from "libs/widgets";
import Widgets from "../widgets";
import MNavigation from "./../modules/MNavigation";

class AppFacade extends Facade {
  Common():void {
    super.Common();
    MNavigation.run(this);
  }

  Widgets():void {
    CouponsGrid.run();
    FeaturedCoupons.run();
    QuickLinks.run();
    Carousel.run();
    PremiumSlider.run();
    OfferCarousel.run();
    PremiumCampaignSlider.run();
    Widgets.run();
  }
}

const App = new AppFacade();

export default App;
