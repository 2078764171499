import App from "../../core/facade";

export default (function() {
  App.on("home_index:init", function() {
    App.log("Home page init");
    App.Widgets();
  });
})();


