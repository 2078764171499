import axios from "axios";
import App from "./core/facade";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
require('./pages');

document.addEventListener('DOMContentLoaded', function() {
  const svg = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23F3A7CA' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m7 15-2 2H1v-4L11.5 2.5a2.828 2.828 0 1 1 4 4l-1 1-4-4m4.3 14.317-2.172 1.138a.392.392 0 0 1-.568-.41l.415-2.411-1.757-1.707a.39.39 0 0 1 .217-.665l2.428-.352 1.086-2.193a.392.392 0 0 1 .702 0l1.086 2.193 2.428.352a.391.391 0 0 1 .217.665l-1.757 1.707.414 2.41a.391.391 0 0 1-.567.41L14.8 17.818Z'/%3E%3C/svg%3E")`;

  App.log('%c %cCoupons%c4%cReal', `font-size:0.4rem; padding:12px; background: ${svg} no-repeat;`, "color:green;font-weight:900;font-size:2rem;font-family:sans-serif;", "color: black;font-weight:900;font-size:2rem;font-family:sans-serif;", "color:pink;font-weight:900;font-size:2rem;font-family:sans-serif;");
  App.start();
});
