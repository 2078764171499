import App from "../core/facade";

export default (function() {
  function run() {
    App.trigger("showmore");
  }

  return {
    run: run
  };

})();
