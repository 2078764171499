import App from "../../core/facade";

export default (function() {
  App.on("campaigns_show:init", function() {
    App.log("Campaign page init");
    App.Widgets();
    App.CampaignCouponsFilter();
    App.JumpToLinks();
    App.HeroCountdown();
  });

  App.on("campaigns_sem:init", function() {
    App.log("Campaign sem page init");
    App.Widgets();
  });
})();
