import type Facade from "libs/Facade";

export default (function() {
  let App: Facade;
  let navigationItems: NodeListOf<HTMLElement>;
  let backdrop: HTMLElement;

  const header = document.querySelector('[data-header]') as HTMLElement;
  const navigationWrapper = header?.querySelector('[data-main-navigation]') as HTMLElement | null;

  function run(parentApp: Facade) {
    App = parentApp;

    if (header && navigationWrapper) {
      navigationItems = navigationWrapper.querySelectorAll(".m-navigation__item");
      backdrop = document.querySelector(".backdrop") as HTMLDivElement;

      App.log("Main navigation init");
      subMenus();
      toggleHamburger();
    }
  }

  function subMenus() {
    const navigationItemsWithSubMenu = Array.from(navigationItems).filter(el => {
      return el.className.indexOf('has-sub-menu') !== -1;
    });

    navigationItemsWithSubMenu.forEach(el => {
      const openSubmenuLink = el.querySelector(".m-navigation__link") as HTMLElement;
      const subMenu = el.querySelector(".sub-menu") as HTMLElement;

      openSubmenuLink.addEventListener("click", ev => {
        if (!App.Utils.isMobile.desktop()) {
          ev.preventDefault();

          navigationItemsWithSubMenu.forEach(el => {
            if (ev.target instanceof HTMLElement && el !== ev.target.closest(".m-navigation__item")) {
              const sub = el.querySelector(".sub-menu") as HTMLElement;
              sub.style.display = "none";
            }
          });

          if (subMenu.style.display === "block") {
            subMenu.style.display = "none";
          } else {
            subMenu.style.display = "block";
          }
        }
      });
    });
  }

  function closeBackdropToClick(hamburger: Element | null, navigation: Element | null, search: Element | null) {
    backdrop.addEventListener("click", () => {
      if (hamburger?.classList.contains('header__hamburger--opened')) {
        hamburger.classList.remove('header__hamburger--opened');
        navigation?.classList.remove('m-navigation--opened');
        search?.classList.remove('sub-nav-bar--opened');
        toggleBackdrop("close");
      }
    });
  }

  function toggleBackdrop(param: "open"|"close") {
    const ev = param;

    if (ev === "open") {
      backdrop.style.display = "block";
    }

    if (ev === "close") {
      backdrop.style.display = "none";
    }
  }

  function toggleHamburger() {
    if(App.Utils.isMobile.desktop()) return;

    const hamburgerButton = header.querySelector('[data-hamburger-menu]') as Element | null ;
    const navigationMenu = header.querySelector('[data-main-navigation]') as Element | null;
    const searchBar = header.querySelector('[data-search-bar]') as Element | null;

    hamburgerButton?.addEventListener('click', ()=> {
      hamburgerButton?.classList.toggle('header__hamburger--opened');

      if(hamburgerButton?.classList.contains('header__hamburger--opened')) {
        toggleBackdrop("open");
      } else (
        toggleBackdrop("close")
      )

      navigationMenu?.classList.toggle('m-navigation--opened');
      searchBar?.classList.toggle('sub-nav-bar--opened');
    })

    closeBackdropToClick(hamburgerButton, navigationMenu, searchBar);
  }

  return {
    run: run
  };
})();
