import App from "../../core/facade";

export default (function() {
  App.on("shops_show:init", function() {
    App.log("Shop page init");
    App.Coupons();
    App.Vote();
    App.CouponFilters();
    App.JumpToLinks();
  });

  App.on("shops_index:init", function() {
    App.log("Shops index page init");
    App.ShopsIndex();
  });
})();
